<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="workOrdersFiltered"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0 pb-4"
                    style="background-color: #eeeeee"
                >
                    <h1 class="mx-4">WORK ORDERS</h1>
                    <v-btn rounded color="primary" @click="findWO = true">
                        <v-icon class="mr-1">
                            mdi-database-search
                        </v-icon>
                        FIND
                    </v-btn>
                    <v-spacer />
                </v-row>
            </template>
            <!--HEADERS-->
            <template v-slot:[`header.code`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="search"
                    dense
                />
            </template>
            <template v-slot:[`header.project`]="{ header }">
                <v-text-field
                    class="py-2 mt-2"
                    :label="header.text"
                    v-model="projectToFilter"
                    dense
                />
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.createdBy`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.createdBy.name }}</p>
                </div>
            </template>
            <template v-slot:[`item.project`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{ item.project.reference }} - {{ item.project.name }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.productionStart`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">
                        {{
                            item.productionStart
                                ? formatDate(item.productionStart.seconds)
                                : ''
                        }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.weight`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ item.weight.toFixed(2) }} kg</p>
                </div>
            </template>
            <template v-slot:[`item.processes`]="{ item }">
                <div v-for="process in item.processesInfo" :key="process.id">
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <p class="my-0 text-left" v-on="on">
                                {{ process.process.name }}:
                                {{ process.produced || 0 }}/{{
                                    process.qty
                                }}
                                ({{
                                    (
                                        ((process.produced || 0) * 100) /
                                        process.qty
                                    ).toFixed(0) || 0
                                }}%)
                            </p>
                        </template>
                        <span>
                            {{ process.process.name }}: <br />
                            Total Quantity =
                            {{ process.qty || 0 }}<br />
                            Available = {{ process.available || 0 }} ({{
                                (
                                    ((process.available || 0) * 100) /
                                    process.qty
                                ).toFixed(0) || 0
                            }}%)<br />
                            Produced =
                            {{ process.produced || 0 }} ({{
                                (
                                    ((process.produced || 0) * 100) /
                                    process.qty
                                ).toFixed(0) || 0
                            }}%)<br />
                            Delivered =
                            {{ process.delivered }} ({{
                                (
                                    ((process.delivered || 0) * 100) /
                                    process.qty
                                ).toFixed(0) || 0
                            }}%)
                        </span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:[`item.quality`]="{ item }">
                <div>
                    <v-tooltip right>
                        <template v-slot:activator="{ on }">
                            <v-col>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #2b81d6;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOPending(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #2fbf56;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOReleased(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #ffa41d;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWOReprocess(item) }}
                                    </p>
                                </v-btn>
                                <v-btn
                                    x-small
                                    icon
                                    class="mr-1"
                                    color="white"
                                    style="background-color: #FF2610;"
                                    v-on="on"
                                >
                                    <p class="my-0">
                                        {{ calculateWORejected(item) }}
                                    </p>
                                </v-btn>
                            </v-col>
                        </template>
                        <span>
                            Pending :
                            {{ calculateWOPending(item) || 0 }}
                            <br />
                            Released :
                            {{ calculateWOReleased(item) }} <br />
                            Reprocess :
                            {{ calculateWOReprocess(item) }} <br />
                            Rejected :
                            {{ calculateWORejected(item) }} <br />
                        </span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:[`item.percentageReceived`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ getPercentageReceived(item) }}</p>
                </div>
            </template>
            <template v-slot:[`item.percentageSent`]="{ item }">
                <div class="d-flex justify-center">
                    <p class="my-0">{{ getPercentageSent(item) }}</p>
                </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn
                            fab
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey lighten-3"
                            depressed
                            class="mx-1"
                        >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click.stop="openProcessWorkOrder(item)">
                            <v-list-item-icon>
                                <v-icon size="20">mdi-eye</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    View
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item
                            v-if="productionClosingPermission"
                            @click.stop="openProductionClosing(item)"
                        >
                            <v-list-item-icon>
                                <v-icon size="20"
                                    >mdi-package-variant-closed-check</v-icon
                                >
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    Close Production
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--PROCESS WORK ORDER-->
        <v-dialog
            v-model="processWorkOrder"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <OperatorProcessWorkOrder
                v-if="processWorkOrder"
                :order="selectedOrder"
                @close="closeProcessWorkOrder"
            />
        </v-dialog>
        <!--FIND WO-->
        <v-dialog
            v-model="findWO"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <FindWO v-if="findWO" @addWO="addWO" @close="findWO = false" />
        </v-dialog>
        <!-- close work order -->
        <v-dialog v-model="productionClosing" persistent max-width="400px">
            <v-card :loading="loading">
                <v-card-title class="text-h5">Close Work Order</v-card-title>
                <v-card-text>
                    Are you sure you want to close this work order?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="closeProductionClosing"
                    >
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeWO"
                        text
                        color="primary"
                        :loading="loading"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import { firestore } from '@/services/firebase'
import API from '@/services/api'
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import SocketioService from '@/services/websocket/socket.service.js'

export default {
    name: 'OperatorWOs',
    props: {
        projectId: {
            type: String,
            default: () => undefined,
        },
        project: {
            type: Object,
            deafult: () => ({}),
        },
    },
    components: {
        OperatorProcessWorkOrder: () =>
            import('@/components/WorkOrders/OperatorProcessWorkOrder.vue'),
        FindWO: () => import('@/components/WorkOrders/FindWO.vue'),
    },
    data: () => ({
        selectedOrder: undefined,
        processWorkOrder: false,
        workOrder: false,
        projectToFilter: undefined,
        loading: false,
        workOrders: [],
        activateAlert: false,
        alertMessage: undefined,
        search: null,
        headers: [
            {
                text: 'ORDER',
                value: 'code',
                align: 'center',
                sortable: false,
                width: 120,
            },
            {
                text: 'CREATED BY',
                value: 'createdBy',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PROJECT',
                value: 'project',
                align: 'center',
                sortable: false,
                width: 240,
            },
            {
                text: 'PRODUCTION START',
                value: 'productionStart',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'center',
                sortable: false,
            },
            {
                text: 'WEIGHT',
                value: 'weight',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QUALITY',
                value: 'quality',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        company: JSON.parse(localStorage.getItem('company')),
        listener: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        enableOptions: true,
        resourceId: undefined,
        processToShow: undefined,
        showProcesses: false,
        settingProcesses: undefined,
        findWO: false,
        user: {},
        productionClosingPermission: false,
        productionClosing: false,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packingProcess: {
            id: 'packing',
            name: 'Packing',
        },
    }),
    computed: {
        ...mapState(['notificationResource']),
        workOrdersFiltered() {
            this.openResource()
            let conditions = []
            if (this.search) {
                conditions.push(this.filterOrder)
            }
            if (this.projectToFilter) {
                conditions.push(this.filterProject)
            }
            if (conditions.length > 0) {
                return this.workOrders.filter(order => {
                    return conditions.every(condition => {
                        return condition(order)
                    })
                })
            }
            this.sort()
            return this.workOrders
        },
    },
    watch: {
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.resourceId = this.$route.query.resourceId
            SocketioService.joinRoom(`${this.companyId}-WO`)
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            // set permission
            this.productionClosingPermission = user.permissions.includes(
                'closeWO'
            )
            if (user.permissions.includes('watchProcesses')) {
                this.showProcesses = true
            } else if (user.process) {
                this.processToShow = user.process
                this.showProcesses = true
            }

            if (this.showProcesses) {
                this.headers.splice(6, 0, {
                    text: 'PROCESSES',
                    value: 'processes',
                    align: 'center',
                    sortable: false,
                })
            }

            if (user.process) {
                this.headers.splice(7, 0, {
                    text: '% RECEIVED',
                    value: 'percentageReceived',
                    align: 'center',
                    sortable: false,
                    width: 100,
                })
                this.headers.splice(8, 0, {
                    text: '% SENT',
                    value: 'percentageSent',
                    align: 'center',
                    sortable: false,
                    width: 100,
                })
            }

            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            const {
                data: { users },
            } = await API.getLiteUsers()
            const projects = await API.getLiteProjects({ all: true })
            let query = firestore
                .collection('companies')
                .doc(this.companyId)
                .collection('workOrders')
                .where('status', '==', 'approved')
            if (!user.permissions.includes('readAllWO')) {
                query = query.where('createdBy', '==', user.id)
            }
            this.listener = await query.onSnapshot(async docSnapshot => {
                docSnapshot.docChanges().forEach(async change => {
                    const workOrder = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    const userIndex = users.findIndex(
                        u => u.id == workOrder.createdBy
                    )
                    if (userIndex >= 0) {
                        workOrder.createdBy = users[userIndex]
                    } else {
                        workOrder.createdBy = { id: workOrder.createdBy }
                        workOrder.createdBy.name = ''
                    }
                    if (change.type === 'added') {
                        const projectIndex = projects.findIndex(
                            p => p.id == workOrder.projectId
                        )
                        if (projectIndex >= 0) {
                            workOrder.project = projects[projectIndex]
                        } else {
                            workOrder.project = await API.getProject(
                                workOrder.projectId
                            )
                            projects.push(workOrder.project)
                        }
                        if (this.showProcesses) {
                            if (!user.permissions.includes('watchProcesses')) {
                                if (
                                    workOrder.items.find(item =>
                                        item.processes.includes(
                                            this.processToShow.id
                                        )
                                    )
                                ) {
                                    this.workOrders.splice(0, 0, workOrder)
                                }
                            } else {
                                this.workOrders.splice(0, 0, workOrder)
                            }
                        } else {
                            this.workOrders.splice(0, 0, workOrder)
                        }
                    }
                    if (change.type === 'modified') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            const projectIndex = projects.findIndex(
                                p => p.id == workOrder.projectId
                            )
                            if (projectIndex >= 0) {
                                workOrder.project = projects[projectIndex]
                            } else {
                                workOrder.project = await API.getProject(
                                    workOrder.projectId
                                )
                                projects.push(workOrder.project)
                            }
                            if (this.showProcesses) {
                                if (
                                    !user.permissions.includes('watchProcesses')
                                ) {
                                    if (
                                        workOrder.items.find(item =>
                                            item.processes.includes(
                                                this.processToShow.id
                                            )
                                        )
                                    ) {
                                        this.workOrders.splice(
                                            index,
                                            1,
                                            workOrder
                                        )
                                    }
                                } else {
                                    this.workOrders.splice(index, 1, workOrder)
                                }
                            } else {
                                this.workOrders.splice(index, 1, workOrder)
                            }
                        }
                    }
                    if (change.type === 'removed') {
                        const index = this.workOrders.findIndex(
                            r => r.id == workOrder.id
                        )
                        if (index >= 0) {
                            this.workOrders.splice(index, 1)
                        }
                    }
                    if (this.showProcesses) {
                        this.setProcessesInfo(workOrder)
                    }
                })
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        async closeWO() {
            try {
                this.loading = true
                await API.closeWorkOrder({
                    workOrderId: this.selectedOrder.id,
                    notificationId: 'ar0vX28u4hadZW2pVn9n',
                })
                this.workOrders = this.workOrders.filter(
                    order => order.id != this.selectedOrder.id
                )
                this.closeProductionClosing()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeProductionClosing() {
            this.selectedOrder = {}
            this.productionClosing = false
        },
        openProductionClosing(workOrder) {
            this.selectedOrder = _.cloneDeep(workOrder)
            this.productionClosing = true
        },
        openProcessWorkOrder(item) {
            this.selectedOrder = _.cloneDeep(item)
            this.processWorkOrder = true
        },
        closeProcessWorkOrder() {
            this.processWorkOrder = false
        },
        filterOrder(order) {
            return order.code.toLowerCase().includes(this.search.toLowerCase())
        },
        filterProject(order) {
            return (
                order.project.name
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase()) ||
                order.project.reference
                    .toString()
                    .toLowerCase()
                    .includes(this.projectToFilter.toLowerCase())
            )
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        sort() {
            this.workOrders.sort(
                (a, b) =>
                    (b.createdOn.seconds || b.createdOn._seconds) -
                    (a.createdOn.seconds || a.createdOn._seconds)
            )
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.workOrders.find(
                    w => w.id == this.resourceId
                )
                if (resource) {
                    this.resourceId = undefined
                    this.openProcessWorkOrder(resource)
                }
            }
        },
        setProcessesInfo(wo) {
            wo.processesInfo = []

            let qualityProcess = {
                process: {
                    id: this.qualityProcess.id,
                    name: this.qualityProcess.name,
                },
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }

            let packingProcess = {
                process: {
                    id: this.packingProcess.id,
                    name: this.packingProcess.name,
                },
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }

            let showDefaultProcesses = false
            wo.items.forEach(item => {
                if (item.processes) {
                    if (this.processToShow) {
                        if (!showDefaultProcesses) {
                            const processIndex = item.processes.findIndex(
                                p => p == this.processToShow.id
                            )

                            showDefaultProcesses =
                                processIndex == item.processes.length - 1
                        }
                    }
                    item.processesToShow = _.cloneDeep(item.processes)
                    if (this.processToShow) {
                        item.processesToShow = item.processesToShow.filter(
                            p => p == this.processToShow.id
                        )
                    }

                    item.processesToShow.forEach(process => {
                        let processInfo = wo.processesInfo.find(
                            info => info.process.id == process
                        )

                        if (!processInfo) {
                            let totalDelivered = item.deliveries
                                ? item.deliveries.reduce(
                                      (total, register) =>
                                          register.prevProcess.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                            let totalProduced = item.production
                                ? item.production.reduce(
                                      (total, register) =>
                                          register.process.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0

                            let received = item.deliveries
                                ? item.deliveries.filter(
                                      register =>
                                          register.nextProcess.id == process &&
                                          register.receivedBy
                                  )
                                : []
                            let totalReceived = received.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                            let processIndex = this.settingProcesses.processes.findIndex(
                                p => p.id == process
                            )

                            const pIndex = item.processes.findIndex(
                                p => p == process
                            )
                            if (pIndex == 0) {
                                totalReceived = item.quantity
                            }
                            wo.processesInfo.push({
                                process: {
                                    id: this.settingProcesses.processes[
                                        processIndex
                                    ].id,
                                    name: this.settingProcesses.processes[
                                        processIndex
                                    ].name,
                                },
                                qty: item.quantity,
                                delivered: totalDelivered,
                                produced: totalProduced,
                                received: totalReceived,
                                available: totalReceived - totalProduced,
                            })
                        } else {
                            processInfo.qty += item.quantity
                            processInfo.delivered += item.deliveries
                                ? item.deliveries.reduce(
                                      (total, register) =>
                                          register.prevProcess.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0
                            processInfo.produced += item.production
                                ? item.production.reduce(
                                      (total, register) =>
                                          register.process.id == process
                                              ? total + register.qty
                                              : total,
                                      0
                                  )
                                : 0

                            const receivedEntries = item.deliveries
                                ? item.deliveries.filter(
                                      e => e.nextProcess == process
                                  )
                                : []

                            processInfo.received += receivedEntries.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }
                    })

                    //ready for packing process
                    let totalProduced = item.production
                        ? item.production.reduce(
                              (total, register) =>
                                  register.process.id ==
                                  this.readyForPackingProcess.id
                                      ? total + register.qty
                                      : total,
                              0
                          )
                        : 0

                    packingProcess.qty += item.quantity
                    packingProcess.delivered = 'NA'
                    packingProcess.produced += item.packedQty
                    packingProcess.available =
                        totalProduced - (packingProcess.produced || 0)

                    //quality process
                    qualityProcess.qty += item.quantity
                    qualityProcess.delivered = 'NA'
                    qualityProcess.produced =
                        this.calculateRejectedQty(item) +
                        this.calculateReleasedQty(item)
                    qualityProcess.available = this.calculateQualityPending(
                        item
                    )
                }
            })
            if (showDefaultProcesses) {
                wo.processesInfo.push(qualityProcess)
                wo.processesInfo.push(packingProcess)
            }
            if (this.selectedOrder && this.selectedOrder.id == wo.id) {
                this.selectedOrder = _.cloneDeep(wo)
            }
        },
        async addWO(wo) {
            try {
                this.loading = true
                const index = this.workOrders.findIndex(w => w.id == wo.id)
                if (index >= 0) {
                    this.openProcessWorkOrder(this.workOrders[index])
                } else {
                    wo.project = await API.getProject(wo.projectId)
                    wo.projectManager = this.users.find(
                        user => user.id == wo.project.projectManager
                    )
                    wo.creator = this.users.find(
                        user => user.id == wo.createdBy
                    )
                    wo.approvedBy = this.users.find(
                        user => user.id == wo.approvedBy
                    )
                    this.workOrders.splice(0, 0, wo)
                    this.openProcessWorkOrder(wo)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getPercentageReceived(wo) {
            let totalReceived = 0
            let totalItems = 0
            wo.items.forEach(item => {
                const processIndex = item.processes.findIndex(
                    processId => this.user.process.id == processId
                )
                if (processIndex > -1) {
                    totalItems += item.quantity
                    if (processIndex == 0) {
                        totalReceived += Number(item.quantity)
                    } else if (processIndex > 0 && item.deliveries) {
                        for (let entry of item.deliveries) {
                            if (
                                entry.nextProcess.id == this.user.process.id &&
                                entry.receivedBy
                            ) {
                                totalReceived += Number(entry.qty)
                            }
                        }
                    }
                }
            })
            const percentage = ((totalReceived / totalItems) * 100).toFixed(1)
            return isNaN(percentage) ? '-' : `${percentage}%`
        },
        getPercentageSent(wo) {
            let totalSent = 0
            let totalItems = 0
            wo.items.forEach(item => {
                const processIndex = item.processes.findIndex(
                    processId => this.user.process.id == processId
                )
                if (processIndex > -1) {
                    totalItems += item.quantity
                    if (item.deliveries) {
                        for (let entry of item.deliveries) {
                            if (entry.prevProcess.id == this.user.process.id) {
                                totalSent += Number(entry.qty)
                            }
                        }
                    }
                }
            })
            const percentage = ((totalSent / totalItems) * 100).toFixed(1)
            return isNaN(percentage) ? '-' : `${percentage}%`
        },
        calculateQualityPending(item) {
            try {
                let pendingQty = 0
                const lastProductionProcess =
                    item.processes[item.processes.length - 1]
                if (item.production) {
                    const production = item.production.filter(
                        entry => entry.process.id == lastProductionProcess
                    )
                    if (production.length > 0) {
                        pendingQty = production.reduce(
                            (accumulator, entry) => accumulator + entry.qty,
                            0
                        )
                    }
                }
                if (item.quality) {
                    const rejected = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    const released = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    pendingQty -= Number(released || 0) + Number(rejected || 0)
                }
                return pendingQty
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReleasedQty(item) {
            try {
                let released = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    released += Number(value || 0)
                }
                return released
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateRejectedQty(item) {
            try {
                let rejected = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    rejected += Number(value || 0)
                }
                return rejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWOPending(wo) {
            try {
                let totalPending = 0
                wo.items.forEach(item => {
                    let pendingQty = 0
                    const lastProductionProcess =
                        item.processes[item.processes.length - 1]
                    if (item.production) {
                        const production = item.production.filter(
                            entry => entry.process.id == lastProductionProcess
                        )
                        if (production.length > 0) {
                            pendingQty = production.reduce(
                                (accumulator, entry) => accumulator + entry.qty,
                                0
                            )
                        }
                    }
                    if (item.quality) {
                        const rejected = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.rejectQty || 0),
                            0
                        )
                        const released = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.releaseQty || 0),
                            0
                        )
                        pendingQty -=
                            Number(released || 0) + Number(rejected || 0)
                    }
                    totalPending += pendingQty
                })

                return totalPending
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWOReleased(wo) {
            try {
                let totalReleased = 0
                wo.items.forEach(item => {
                    let released = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.releaseQty || 0),
                            0
                        )
                        released += Number(value || 0)
                    }
                    totalReleased += released
                })
                return totalReleased
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWOReprocess(wo) {
            try {
                let totalReprocess = 0
                wo.items.forEach(item => {
                    let reprocess = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.reprocessQty || 0),
                            0
                        )
                        reprocess += Number(value || 0)
                    }
                    totalReprocess += reprocess
                })

                return totalReprocess
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateWORejected(wo) {
            try {
                let totalRejected = 0
                wo.items.forEach(item => {
                    let rejected = 0
                    if (item.quality) {
                        const value = item.quality.reduce(
                            (accumulator, entry) =>
                                accumulator + (entry.rejectQty || 0),
                            0
                        )
                        rejected += Number(value || 0)
                    }
                    totalRejected += rejected
                })

                return totalRejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
    },
}
</script>

<style>
.v-input .v-label {
    font-size: 12px;
}
</style>
